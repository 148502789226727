import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
// import ExperiencePage from './pages/ExperiencePage';
import TeamPage from './pages/TeamPage';
import TeamHeadsetAddPage from './pages/TeamHeadsetAdd';
import TeamBillingPage from './pages/TeamBillingPage';
import Wrapper from './pages/wrapper';


const PortalRoutes = () => (
  <Routes>
      <Route path="/" element={<Wrapper/>}>
        <Route index element={<HomePage />} />
        <Route path="/team">
            <Route path=":teamId" element={<TeamPage />} />
            <Route path=":teamId/billing" element={<TeamBillingPage />} />
            <Route path=":teamId/add" element={<TeamHeadsetAddPage />} />
        </Route>
        {/* <Route path="/experience">
            <Route path=":expId" element={<ExperiencePage />} />
        </Route> */}
        {/* <Route path=":id" element={<EditPage />} > */}
            {/* <Route path="publish" exact render={(props) => (<AuditPublishPage {...props} />)} /> */}
            {/* <Route path=":aid" element={<ViewAsrqPage />} /> */}
      </Route>
  </Routes>
);

export default PortalRoutes;
