import React from "react";
import { Link, } from "react-router-dom";
import { Container, Row, Col, Nav, } from "react-bootstrap";


export default function HomeFooter() {
    return (
        <div className="mt-5 py-5 border-top bg-light">
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <Nav className="flex-column">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/auth/login">Login</Nav.Link>
                </Nav>
              </Col>
              <Col xs={12} md={4}>
                <h4>CrosseyeVR</h4>
                *** Contact info here ***
              </Col>
            </Row>
          </Container>
      </div>
    );
}
