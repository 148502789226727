import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    //   useQuery,
    //   gql
    from,
} from "@apollo/client";
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';


const batchLink = new BatchHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,  // "http://localhost:4401"
    batchMax: 5, // no more than 5 operations batched together
    batchInterval: 60, // Wait no more than XXXms after first operation to call the server
    credentials: 'include'
});
const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
        // if (operation.operationName == "specialSomething") { return false; }
        return !!error;
    },
    delay: (count, operation, error) => {
        // Jitter of +/- 10%
        let myCount = count < 10 ? count : 10;
        return myCount * 1000 * (Math.random() / 5 + 0.9);
    }
});
const graphLink = from([retryLink, batchLink]);
const graphqlClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: graphLink
});

 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
            <ApolloProvider client={graphqlClient}>
                <App />
            </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
