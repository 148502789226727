import React, {useState, useCallback} from "react";
import {
  Link,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { Alert, Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import HomeFooter from '../../home/components/footer';
import { useGraphQL, useGraphMutation, gql } from '../../hooks/graphql-hook';

// import { importMDX } from 'mdx.macro';
// const TestDocument = importMDX.sync('../../content/test.mdx');

const HOME_GQL = gql`query {
    me {
        id
        name
    }
    teams {
        nodes {
            id
            name
            devices {
                totalCount
            }
        }
    }
}`;
const GQL_CREATETEAM = gql`mutation($name:String!) {
    createTeam(name:$name) {
        id
        name
    }
}`;

function TeamListItem({team}) {
  return (<Card className="shadow">
  <Card.Body className="bg-secondary text-white">
      <h4 className="pt-5">{team.name ?? "Team name here"}</h4>
  </Card.Body>
  <Card.Body className="">
      <div className="text-muted">VR Headsets</div>
      <div>0 of 0</div>
  </Card.Body>
  <Card.Body className="">
      <Link to={`/dashboard/team/${team.id}`} className="btn btn-lg btn-outline-primary">Manage</Link>
  </Card.Body>
</Card>);
}

function FirstTeam({xx}) {
    const navigate = useNavigate();
    const [fData, setFData] = useState({name:""});
    const mutation = useGraphMutation(GQL_CREATETEAM);
    const mutate = mutation.mutate;
    const submit = useCallback((e) => {
        e.preventDefault();
        console.log(`Creating team`, fData);
        mutate({variables:fData, update(cache, {data: {createTeam}}) {
            console.log(`Created team`, createTeam);
            navigate(`/dashboard/team/${createTeam.id}`);
        } });
    }, [mutate, navigate,fData]);
    const formSetName = useCallback(e=> {
        setFData(d=>{
            console.log(`Form Set Name`, {d, value: e.target.value})
            return ({...d, name:e.target.value});
        });
    }, [setFData]);
    return (<Alert variant="success">
        To manage 360 videos on your headsets, first create a team.
        <Form onSubmit={submit}>
        <Form.Group className="my-3">
            <Form.Label>Team Name</Form.Label>
            <Form.Control type="text" placeholder="My Ministry" value={fData.name} onChange={formSetName} />
        </Form.Group>
        
        <div className="text-end"><Button variant="success" onClick={submit}>Create team</Button></div>
        </Form>
    </Alert>);
}

function TeamsList({teams}) {
  return (<div>
        <h4 className="border-bottom">My Teams</h4>
        <Row>
            {teams.length == 0 && (<FirstTeam/>)}
            {teams.map(team => (<Col xs={12} sm={6} md={4}>
                <TeamListItem team={team} />
            </Col>))}
        </Row>
  </div>);
}



export default function HomePage() {
    const jwtToken = localStorage.getItem('auth:jwt');
    const apiData = useGraphQL(HOME_GQL, {});
    const userName = apiData.data?.me?.name ?? "";
    const teams = apiData.data?.teams?.nodes ?? [];


    return (<Container className="mt-3">
        <Alert color="info">Welcome to CrosseyeVR!</Alert>
        <h2>Welcome {userName}</h2>

        <TeamsList teams={teams}/>

        <hr className="mt-4" />

        <div>
            <p>For support, contact us at team@unwritten.media.</p>
        </div>

        <div>JWT:<pre className="border px-2">{jwtToken}</pre></div>
        <div>Build Environment:<pre className="border px-2">{JSON.stringify(process.env,null,4)}</pre></div>
    </Container>);
}