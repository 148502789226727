import React, {useState, useCallback} from "react";
import {
  Link,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Alert, Breadcrumb, Container, Card, Row, Col, Badge, Form, Button, Table } from "react-bootstrap";
import HomeFooter from '../../home/components/footer';
import { useGraphQL, useGraphMutation, gql } from '../../hooks/graphql-hook';

// import { importMDX } from 'mdx.macro';
// const TestDocument = importMDX.sync('../../content/test.mdx');

const prettyBytes = (num, precision = 3, addSpace = true) => {
    if (num == null) {
        return null;
    }
    const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (Math.abs(num) < 1) return num + (addSpace ? ' ' : '') + UNITS[0];
    const exponent = Math.min(Math.floor(Math.log10(num < 0 ? -num : num) / 3), UNITS.length - 1);
    const n = Number(((num < 0 ? -num : num) / 1000 ** exponent).toPrecision(precision));
    return (num < 0 ? '-' : '') + n + (addSpace ? ' ' : '') + UNITS[exponent];
};
  
const TEAM_GQL = gql`query($teamId:String!) {
    me {
        id
        name
    }
    team(id:$teamId) {
        id
        name
        paidDeviceCount
        serviceCredits

        devices {
            totalCount
            nodes {
                id
                name
            }
        }

        purchasedMedia {
            nodes {
                id
                name
                type
                downloadSize
                downloadURL
            }
        }
    }
}`;
const GQL_CREATETEAM = gql`mutation($name:String!) {
    createTeam(name:$name) {
        id
        name
    }
}`;

function TeamListItem({team}) {
  return (<Card className="shadow">
  <Card.Body className="bg-secondary text-white">
      <h4 className="pt-5">Team name here</h4>
  </Card.Body>
  <Card.Body className="">
      <div className="text-muted">VR Headsets</div>
      <div>0 of 0</div>
  </Card.Body>
  <Card.Body className="">
      <Link to={`/dashboard/team/${team.id}`} className="btn btn-lg btn-outline-primary">Manage</Link>
  </Card.Body>
</Card>);
}

function DevicesTable({paidCount, teamId, devices}) {
    const getStarted = (paidCount == null || paidCount == 0);
    let unusedRows = paidCount - devices.length; //getStarted ? 5 : paidCount - (0);

    if (getStarted) {
        return (<><h4>Devices - VR Headsets</h4>
            <Card className="text-center p-3">
                <div className="fs-1 text-secondary">No devices paid.</div>
                <div className="fs-4 mb-3">Upgrade to add your first!</div>
                <div><Link to={`/dashboard/team/${teamId}/billing`}>Add billing</Link></div>
            </Card>
        </>);
    }
    
    return (<Card>
        <Card.Header>Devices - VR Headsets</Card.Header>
        <>
    <Table>
        <thead><tr>
            <th>Device</th>
            <th>Mode</th>
            <th className="text-end">Last Seen</th>
        </tr></thead>
        <tbody>
            {devices.map(device=>(<tr key={device.id} className="table-light">
                <td className="">
                    <div>{device?.name ?? "--"}</div>
                    <div className="fw-lighter">{device?.id ?? "--"}</div>
                </td>
                <td><Badge>Activated</Badge></td>
                <td className="text-end"></td>
            </tr>))}
            {[...Array(unusedRows).keys()].map(key=>(<tr key={key} className="table-light">
                <td className="fst-italic">Available Device slot</td>
                <td></td>
                <td className="text-end"><Link to={`/dashboard/team/${teamId}/add`} className="btn btn-outline-primary btn-sm">Enter Code</Link></td>
            </tr>))}
        </tbody>
    </Table></><Card.Footer className="bg-white text-center text-muted">Need more? Upgrade your device count!</Card.Footer></Card>);
}

function SupportCard() {
    return (<Card>
        <Card.Header>Support</Card.Header>
        <Table className="card-body">
            <thead><tr>
                <th>Subject</th>
                <th className="text-end">Status</th>
            </tr></thead>
            <tbody>
                
            </tbody>
        </Table>
    </Card>);
}

function ExperienceLine({experience}) {
    return (<tr>
        <td><Badge>{experience.type}</Badge></td>
        <td>{experience.downloadURL == null ? experience.name : (<a href={experience.downloadURL}>{experience.name}</a>)}</td>
        {/* <td>{JSON.stringify(experience,null,4)}</td> */}
        <td className="text-end">{prettyBytes(experience.downloadSize)}</td>
    </tr>)
}

function ExperiencesCard({experiences}) {
    
    return (<Card>
        <Card.Header>Experiences &amp; Tools</Card.Header>
        <Table className="card-body">
            <thead><tr>
                <th>Type</th>
                <th>Experience</th>
                <th className="text-end">Size</th>
            </tr></thead>
            <tbody>
                {experiences.map(e=>(<ExperienceLine key={e.id} experience={e} />))}
            </tbody>
        </Table>
    </Card>);
}


export default function TeamPage() {
    const jwtToken = localStorage.getItem('auth:jwt');
    const {teamId} = useParams();
    const apiData = useGraphQL(TEAM_GQL, {teamId});
    const userName = apiData.data?.me?.name ?? "";
    const team = apiData.data?.team ?? {name:teamId};
    const experiences = apiData.data?.team?.purchasedMedia?.nodes ?? [];

    return (<Container className="mt-3">
        {/* <Alert color="info">Welcome to CrosseyeVR!</Alert> */}
        <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to:`/dashboard/`}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{team?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <h2>{team.name}</h2>

        <Row className="my-3 border-bottom border-top pt-3">
            <Col xs={12} md={6}>
                <div className="mb-3">
                    <div><strong className="me-2">VR Devices:</strong>{team?.devices?.totalCount ?? "--"} of {team?.paidDeviceCount ?? "--"}</div>
                    <div><strong className="me-2">Support Credits:</strong>{team?.serviceCredits}</div>
                </div>
            </Col>
            <Col xs={12} md={6}>
                <div className="mb-3">
                    <div><strong className="me-2">Next Bill Date:</strong>{"Jan 0, 2000"}</div>
                    <div><strong className="me-2">Amount:</strong>${0}</div>
                    <div><Link to={`/dashboard/team/${teamId}/billing`}>Upgrade or manage</Link></div>
                </div>
            </Col>
        </Row>

        <div className="mb-5">
            <DevicesTable teamId={teamId} paidCount={team?.paidDeviceCount} devices={team?.devices?.nodes ?? []} />
        </div>

        <div className="mb-5">
            <ExperiencesCard experiences={experiences}/>
            {/* <h4>Tools</h4>
            <ul>
                <li><a href="https://developer.cloud.unity3d.com/share/share.html?shareId=WkwSCYoea_">Oculus Quest app download</a></li>
                <li>Companion app - coming soon</li>
            </ul> */}
        </div>

        <div className="mb-5">
            <SupportCard/>
        </div>

        <hr className="mt-4" />
        <div>CrosseyeVR Online Portal  &copy; {new Date().getUTCFullYear()}</div>

    </Container>);
}