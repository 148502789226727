import { gql as _gql, useQuery, useMutation } from '@apollo/client';


/**
 * Changing the query dynamically is supported.
 * 
 * @param {gql} query an atual raw graphql query 
 * @param {dict} variables used in that operation. See the runconstruction.js file.
 */
export function useGraphQL(query, variables) {
    return useQuery(query, {
        variables,
        notifyOnNetworkStatusChange: true, // Enabling this option also ensures that the value of loading updates accordingly
    });
}

/**
 * Poll every N seconds. Changing the query dynamically is supported.
 * 
 * @param {gql} query an atual raw graphql query 
 * @param {dict} variables used in that operation. See the runconstruction.js file.
 */
export function usePollingGraphQL(query, variables, pollInterval) {
    return useQuery(query, {
        variables,
        pollInterval, 
        notifyOnNetworkStatusChange: true, // Enabling this option also ensures that the value of loading updates accordingly
    });
}

/**
 * Perform a mutation on the server.
 * 
 * @param {gql} query an atual raw graphql query 
 * @returns {[mutateFunction, {data, loading, error}]}
 */
 export function useGraphMutation(query) {
    let ret = {};
    const [mutateFunction, { data, loading, error }] = useMutation(query);
    ret.mutate = mutateFunction;
    ret.data = data;
    ret.loading = loading;
    ret.error = error;
    return ret;
}



export const gql = _gql;