import React, {useState, useCallback} from "react";
import {
  Link,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Alert, Breadcrumb, Container, Card, Row, Col, Form, Button, Table } from "react-bootstrap";
import HomeFooter from '../../home/components/footer';
import { useGraphQL, useGraphMutation, gql } from '../../hooks/graphql-hook';

// import { importMDX } from 'mdx.macro';
// const TestDocument = importMDX.sync('../../content/test.mdx');

const TEAM_GQL = gql`query($teamId:String!) {
    me {
        id
        name
    }
    team(id:$teamId) {
        id
        name
        paidDeviceCount
        serviceCredits

        devices {
            totalCount
        }
    }
}`;
const GQL_ADDHEADSET = gql`mutation($team:String!, $code:String!, $name:String) {
    addHeadset(team:$team, code:$code, name:$name) {
        id
        name
    }
}`;

function FormErrors({errors}) {
    if (errors == null || errors.length == 0) {
        return null;
    }
    return (<>{errors.map(e=>( <div className="text-danger mb-2">{e.message}</div> ))}</>);
}


function AddForm({teamId}) {
    const navigate = useNavigate();
    const [disableCheckout, setDisableCheckout] = useState(false);
    const [myform, setMyform] = useState({team: teamId, code: "", name: ""});
    const [errors, setErrors] = useState([]);

    // const apiData = {};//useGraphQL(null, myform);
    // const checkoutPreview = apiData.data?.simpleBillingPreview ?? {};

    const checkoutMutation = useGraphMutation(GQL_ADDHEADSET);
    const checkoutMutationMutate = checkoutMutation.mutate;
    let errors2 = [...errors];
    if (checkoutMutation.error != null) {
        errors2.push(checkoutMutation.error);
    }
    // if (apiData.error != null) {
    //     errors2.push(apiData.error);
    // }

  
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        console.log("starting adoption", {...myform});
        setErrors([]);
        
        setDisableCheckout(true);
        
        // console.log(`Sending update to server:`, data);
        checkoutMutationMutate({
            variables: myform,
            update: () => {
                navigate(`/dashboard/team/${teamId}`);
            }
        });
        setDisableCheckout(false);
    };
  
    return (<form onSubmit={handleSubmit}>
        {/* {JSON.stringify(checkoutPreview)} */}
        <FormErrors errors={errors2}/>
        <Form.Group className="mb-3" controlId="formDeviceCount">
            <Form.Label>Code (6 digits)</Form.Label>
            <Form.Control className="fs-1" placeholder="--- ---" type="text" maxlength="6" value={myform.devices} onChange={(e)=>setMyform({...myform, code:(e.target.value)}) } />
            <Form.Text className="text-success">Find this headset in the headset once you start it.</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSupportTokens">
            <Form.Label>Name (optional)</Form.Label>
            <Form.Control type="text" value={myform.support} onChange={(e)=>setMyform({...myform, name:(e.target.value)}) } />
            <Form.Text className="text-muted">Give this device a friendly name.</Form.Text>
        </Form.Group>
        <FormErrors errors={errors2}/>
        <div className="mt-3 text-end">
            <Button variant="success" disabled={disableCheckout||checkoutMutation.loading} onClick={handleSubmit}>Activate</Button>
        </div>
    </form>);
};



export default function TeamHeadsetAddPage() {
    const jwtToken = localStorage.getItem('auth:jwt');
    const {teamId} = useParams();
    const apiData = useGraphQL(TEAM_GQL, {teamId});
    const userName = apiData.data?.me?.name ?? "";
    const team = apiData.data?.team ?? {name:teamId};


    return (<Container className="mt-3">
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{to:`/dashboard/`}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to:`/dashboard/team/${teamId}`}}>{team?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Add VR Headset</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="my-3 pt-3">
            <Col xs={12} md={6}>
                <Card>
                    <Card.Body>
                        <AddForm teamId={teamId} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <hr className="mt-4" />

    </Container>);
}