import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import HomeNavbar from '../../home/components/navbar';


export default function HomeWrapperPage() {
    return (
        <>
          <HomeNavbar/>
          <Outlet/>
        </>
      );    
}