import React, {useState, useCallback} from "react";
import {
  Link,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Alert, Breadcrumb, Container, Card, Row, Col, Form, Button, Table } from "react-bootstrap";
import HomeFooter from '../../home/components/footer';
import { useGraphQL, useGraphMutation, gql } from '../../hooks/graphql-hook';
import { StripeWrapper, StripeCheckoutForm } from '../components/Stripes';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

// import { importMDX } from 'mdx.macro';
// const TestDocument = importMDX.sync('../../content/test.mdx');

const TEAM_GQL = gql`query($teamId:String!) {
    me {
        id
        name
    }
    team(id:$teamId) {
        id
        name

        devices {
            totalCount
        }
    }
}`;
const GQL_CHECKOUT = gql`mutation($team:String!, $devices:Int!, $support:Int!, $stripeToken:String!) {
    simpleTeamBilling(team:$team, devices:$devices, support:$support, stripeToken:$stripeToken) {
        devices
        deviceCost
        deviceTotal
        supports
        supportCost
        supportTotal
        salesTax
        total
    }
}`;
const GQL_PREVIEW_CHECKOUT = gql`query($team:String!, $devices:Int!, $support:Int!) {
    simpleBillingPreview(team:$team, devices:$devices, support:$support) {
        devices
        deviceCost
        deviceTotal
        supports
        supportCost
        supportTotal
        salesTax
        total
    }
}`;

function FormErrors({errors}) {
    if (errors == null || errors.length == 0) {
        return null;
    }
    return (<>{errors.map(e=>( <div className="text-danger mb-2">{e.message}</div> ))}</>);
}

function centsToFormat(cents) {
    if (cents == null || isNaN(cents)) {
        return null;
    }
    return "$" + (Math.floor(cents/100)) + "." + ((cents/10)%10) + (cents%10);
}

function CheckoutForm({teamId}) {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [disableCheckout, setDisableCheckout] = useState(false);
    const [myform, setMyform] = useState({team: teamId, devices: 1, support: 0});
    const [errors, setErrors] = useState([]);

    const apiData = useGraphQL(GQL_PREVIEW_CHECKOUT, myform);
    const checkoutPreview = apiData.data?.simpleBillingPreview ?? {};

    const checkoutMutation = useGraphMutation(GQL_CHECKOUT);
    const checkoutMutationMutate = checkoutMutation.mutate;
    let errors2 = [...errors];
    if (checkoutMutation.error != null) {
        errors2.push(checkoutMutation.error);
    }
    if (apiData.error != null) {
        errors2.push(apiData.error);
    }

  
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        console.log("starting checkout", {a:true});
        setErrors([]);
        
        if (!stripe || !elements || disableCheckout) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setDisableCheckout(true);
        
        const result = await stripe.createToken(elements.getElement(CardElement));
        
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            setErrors([{message: result.error.message}]);
            setDisableCheckout(false);
            return;
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            console.log("Stripe token success. Sending to server", result);
        }
        let data = {
            ...myform,
            stripeToken: result.token.id
        };
        console.log(`Sending update to server:`, data);
        checkoutMutationMutate({
            variables: data,
            update: () => {
                navigate(`/dashboard/team/${teamId}`);
            }
        });
        setDisableCheckout(false);
    };
  
    return (<form onSubmit={handleSubmit}>
        {/* {JSON.stringify(checkoutPreview)} */}
        <FormErrors errors={errors2}/>
        <Form.Group className="mb-3" controlId="formDeviceCount">
            <Form.Label>Concurrent Devices</Form.Label>
            <Form.Control type="number" value={myform.devices} onChange={(e)=>setMyform({...myform, devices:parseInt(e.target.value)}) } />
            {/* <Form.Text className="text-danger">You must have more than the number of current devices.</Form.Text> */}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSupportTokens">
            <Form.Label>Support Incidents</Form.Label>
            <Form.Control type="number" value={myform.support} onChange={(e)=>setMyform({...myform, support:parseInt(e.target.value)}) } />
            <Form.Text className="text-muted">1 token per email, 2 per phone-based incident.</Form.Text>
        </Form.Group>
        <div className="border py-2"><StripeCheckoutForm /></div>

        <div className="border-top border-bottom my-4 p-2">
        <div className="mt-3">
                <Row className="border-bottom"><Col xs={8}><strong>Monthly</strong></Col><Col className=""><div className=""><strong>{""}</strong></div></Col></Row>
                <Row><Col xs={8}><span className="ps-3">Devices</span></Col><Col className="text-end">{myform.devices}</Col></Row>
                <Row><Col xs={8}><span className="ps-3">Support (not-recurring)</span></Col><Col className="text-end">{0}</Col></Row>
            </div>
            <div className="mt-3">
                <Row><Col xs={8}>{checkoutPreview.devices} Devices @ {centsToFormat(checkoutPreview.deviceCost)}</Col><Col className="text-end">{centsToFormat(checkoutPreview.deviceTotal)}</Col></Row>
                <Row><Col xs={8}>{checkoutPreview.supports} Support @ {centsToFormat(checkoutPreview.supportCost)}</Col><Col className="text-end">{centsToFormat(checkoutPreview.supportTotal)}</Col></Row>
                <Row><Col xs={8}><strong>Billed Today</strong></Col><Col className="text-end"><div className="border-top"><strong>{centsToFormat(checkoutPreview.total)}</strong></div></Col></Row>
            </div>

        </div>
        <FormErrors errors={errors2}/>
        <div className="mt-3 text-end">
            <Button disabled={!stripe||disableCheckout||checkoutMutation.loading} onClick={handleSubmit}>Update Billing</Button>
        </div>
    </form>);
};
  


export default function TeamBillingPage() {
    // const jwtToken = localStorage.getItem('auth:jwt');
    const {teamId} = useParams();
    const apiData = useGraphQL(TEAM_GQL, {teamId});
    // const userName = apiData.data?.me?.name ?? "";
    const team = apiData.data?.team ?? {name:teamId};


    return (<Container className="mt-3">
        {/* <Alert color="info">Welcome to CrosseyeVR!</Alert> */}
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{to:`/dashboard/`}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to:`/dashboard/team/${teamId}`}}>{team?.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>Billing</Breadcrumb.Item>
        </Breadcrumb>
        <h2>{team.name}</h2>

        <Row className="my-3 border-bottom border-top pt-3">
            <Col xs={12} md={6}>
                <div className="mb-3">
                    <div><strong className="me-2">VR Devices:</strong>{0} of {0}</div>
                    <div><strong className="me-2">Support Credits:</strong>{0}</div>
                </div>
            </Col>
            <Col xs={12} md={6}>
                <div className="mb-3">
                    <div><strong className="me-2">Next Bill Date:</strong>{"Jan 0, 2000"}</div>
                    <div><strong className="me-2">Amount:</strong>${0}</div>
                </div>
            </Col>
        </Row>

        <div className="mb-5">
            <Card>
                <Card.Body>
                <StripeWrapper>
                    <h5>Subscription Info</h5>
                    <div className="py-2"><CheckoutForm teamId={teamId} /></div>
                </StripeWrapper>
                </Card.Body>
            </Card>
        </div>

    </Container>);
}