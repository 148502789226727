import {Elements, CardElement,} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY ?? 'pk_test_nq5Iv0J101oRaSDG34o2QkJx');

export function StripeWrapper({children}) {
    const options = {
        // passing the client secret obtained from the server
        // clientSecret: '{{CLIENT_SECRET}}',
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            {children}
        </Elements>
    );
};


export function StripeCheckoutForm() {
    return (<CardElement />);
};




