import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import Wrapper from './pages/wrapper';


const AuthRoutes = () => (
  <Routes>
      <Route path="/" element={<Wrapper/>}>
        <Route index element={<HomePage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<div className="py-5 container"><h1>404: Unknown auth route</h1></div>} />
        {/* <Route path="new" element={<ViewAsrqPage />} /> */}
        {/* <Route path=":id" element={<EditPage />} > */}
            {/* <Route path="publish" exact render={(props) => (<AuditPublishPage {...props} />)} /> */}
            {/* <Route path=":aid" element={<ViewAsrqPage />} /> */}
      </Route>
  </Routes>
);

export default AuthRoutes;
