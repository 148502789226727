import React from "react";
// import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import { Container } from "react-bootstrap";

// import HomeIndexPage from "./home/pages/HomePage";
// import HomeWrapperPage from "./home/pages/wrapper";
import AuthRoutes from "./auth/auth-routes";
import HomeRoutes from "./home/home-routes";
import PortalRoutes from "./portal/portal-routes";

// import logo from './logo.svg';
import './App.scss';


// const routerRegular = createBrowserRouter([
//   {
//     path: "/",
//     element: <div>Hello world!</div>,
//   },
// ]);


function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/">
                {/* <Route index element={<HomeWrapperPage><HomeIndexPage /></HomeWrapperPage>} /> */}
                <Route index element={<HomeRoutes />} />
                <Route path="auth/*" element={<AuthRoutes />} />
                <Route path="dashboard/*" element={<PortalRoutes />} />
            </Route>
    ));

    return (<RouterProvider router={router} />);
}


export default App;
