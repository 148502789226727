import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import Wrapper from './pages/wrapper';


const HomeRoutes = () => (
  <Routes>
      <Route path="/" element={<Wrapper/>}>
        <Route index element={<HomePage />} />
        {/* <Route path="new" element={<ViewAsrqPage />} /> */}
        {/* <Route path=":id" element={<EditPage />} > */}
            {/* <Route path="publish" exact render={(props) => (<AuditPublishPage {...props} />)} /> */}
            {/* <Route path=":aid" element={<ViewAsrqPage />} /> */}
      </Route>
  </Routes>
);

export default HomeRoutes;
