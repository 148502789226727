import React from "react";
import {
  Link,
  Outlet,
} from "react-router-dom";
import { Container, Card, Row, Col, Badge, Button } from "react-bootstrap";

// import { importMDX } from 'mdx.macro';
// const TestDocument = importMDX.sync('../../content/test.mdx');


function Hero1() {
  return (<Container className="py-4 text-white">
    <Row>
      <Col xs={12} md={6}>
        <h1 className="fs-1">Engage like never before</h1>
        <p className="lead fs-2"><b className="text-warning">CrosseyeVR</b> is the best platform to connect with small and large groups
          using <b className="text-warning">Virtual Reality</b>.
        </p>
        <div>
          <Link to="/more" className="btn btn-lg btn-light text-primary">Learn More</Link>
        </div>
      </Col>
    </Row>
  </Container>);
}

// function Background() {
//   return (
//   <path d="M2979.53 1516.28c-827.69 158.47-2632.632 2819.65-2437.179 3554.1 195.454 734.45 2624.069 416.83 3261.719-455.74 637.64-872.56 3.14-3256.83-824.54-3098.36z" fill="#607D8B" fill-opacity=".07">
//   </path>
//   <path d="M846.586 8949.94c389.554 388.27 2022.774 19.72 2456.914-521.52 434.15-541.25 625.08-2352.28 166.86-2749.52-458.21-397.23-2050.74 288.32-2426.08 790.96-375.33 502.64-587.246 2091.8-197.694 2480.08z" fill="#607D8B" fill-opacity=".05">
//   </path>
//   <path d="M1395.53 800.592c475.71-226.323 2092.45 500.208 2450.3 1132.758 357.84 632.54 116.66 2262.52-405.02 2482.57-521.67 220.06-2095.57-695.7-2414.71-1293.14-319.135-597.44-106.289-2095.86 369.43-2322.188h0z" stroke="#FFCA28" stroke-miterlimit="10" stroke-width="25">
//   </path>
//  </svg>)
// }

function Pricing1() {
  return (<Container className="pt-4">
    <Card>
      <Card.Body>
      <h2 className="text-center mb-4">Early Access Pricing</h2>
      
      <Row>
        <Col xs={12} lg={4} className="pb-4">
          <Card><Card.Body>
          <h3>Headset</h3>
          <div>
            Per-headset pricing
          </div>
          <div className="mt-3 fs-3 text-success">
            $10<small>/mo</small>
          </div>
          </Card.Body></Card>
        </Col>

        <Col xs={12} lg={4} className="pb-4">
          <Card><Card.Body>
          <h3>Presenter</h3>
          <div>
            Self-guided - <Badge>Free</Badge>
          </div>
          <div>
            Presenter, synchronized - <Badge>Free</Badge>
            <div className="text-muted fs-5">Windows &amp; Mac presentation control</div>
          </div>
          <div>
            Your own 360 experiences - <Badge>Free</Badge>
            <div className="text-muted fs-5">Upload your own 360 movies to present</div>
          </div>
          <div>
            Offline support
            <div className="text-muted fs-5">VR requires large files, don't wait for hours. The average time to add content to a headset is about 2 minutes!</div>
          </div>
          <div className="mt-3 fs-3 text-success">
            $0
          </div>
          </Card.Body></Card>
        </Col>

        <Col xs={12} lg={4} className="pb-4">
          <Card><Card.Body>
          <h3>Support</h3>
          <div>
            Per-incident credits
          </div>
          <div className="mt-3 fs-3 text-success">
            $20<small>/credit</small>
          </div>
          </Card.Body></Card>
        </Col>
      </Row>

      </Card.Body>
    </Card>

  </Container>);
}

function signInWithGoogle() {
  alert("TOOD: Hook up to firebase.js lib");
}

export default function HomePage() {
    return (<>
      <div className="bg-primary py-5">

        {/* <Container>
          <Card>
            <Card.Body>
              <h3 className="text-center">Login</h3>
            </Card.Body>
          </Card>
        </Container> */}


  <Card className="ms-auto me-auto w-50" role="document">
    <Card.Body className="rounded-4 shadow">
      <div className="modal-header p-5 pb-4 border-bottom-0">
        <h1 className="fw-bold mb-0 fs-2">Sign up for free</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div className="modal-body p-5 pt-0">
        <form className="">
          <div className="form-floating mb-3">
            <input type="email" className="form-control rounded-3" id="floatingInput" placeholder="name@example.com"/>
            <label for="floatingInput">Email address</label>
          </div>
          <div className="form-floating mb-3">
            <input type="password" className="form-control rounded-3" id="floatingPassword" placeholder="Password"/>
            <label for="floatingPassword">Password</label>
          </div>
          <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Sign up</button>
          <small className="text-muted">By clicking Sign up, you agree to the terms of use.</small>
          <hr className="my-4" />
          <h2 className="fs-5 fw-bold mb-3">Or use a third-party</h2>
          <Button className="w-100 py-2 mb-2 btn-outline-danger rounded-3" type="submit" onClick={signInWithGoogle}>
            Sign up with Google
          </Button>
        </form>
      </div>
    </Card.Body>
  </Card>

        
      </div>

        </>
      );    
}