import React, {useEffect} from "react";
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithRedirect,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    getRedirectResult,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


//TODO: Pull from firebase
// https://blog.logrocket.com/user-authentication-firebase-react-apps/
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAWzfhMeABIHLqn-HleICA71DA8hG9yIRU",
    authDomain: "crosseyevr.firebaseapp.com",
    projectId: "crosseyevr",
    storageBucket: "crosseyevr.appspot.com",
    messagingSenderId: "748839990941",
    appId: "1:748839990941:web:1ea85ddbb997fb3da94674",
    measurementId: "G-H1LWSC333X"
  };
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// ​​const db = getFirestore(app);

/* Setup auth */
const auth = getAuth(app);
// auth.languageCode = 'en';


const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
// googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
// provider.setCustomParameters({'login_hint': 'user@example.com'});

export const signInWithGoogle = async () => {
  try {
    console.log(`signInWithGoogle request:`, auth);
    const res = await signInWithRedirect(auth, googleProvider);
    // The `signInWithRedirect` won't return, it redirects. We have to pickup in a route.
    // const res = await signInWithPopup(auth, googleProvider);
    console.log(`signInWithGoogle response:`, res);
    const user = res.user;
    // const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   await addDoc(collection(db, "users"), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //   });
    // }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

/** Handler that waits (will be a new page load), and receives the Firebase token when authorized. */
export const usePostSigninWithGoogleResult = (setFirebaseToken) => {
    useEffect(() => {
        getRedirectResult(auth)
        .then(function(result) {
            console.log(`Google Auth result`, result);
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            console.log(`Google Auth Token: `, token);
            // setToken(token);
            // ...
            // The signed-in user info.
            var user = result.user;
            console.log(`Google Auth user info:`, user);
            //setData(user);
            setFirebaseToken(user.accessToken);
        })
        .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            console.log(`Google auth error: ${errorCode}`, errorMessage);
            // ...
        });
    }, [setFirebaseToken]);
}

const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
};

/*
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name,
        authProvider: "local",
        email,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
};
*/



  
